.cards{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 3rem 0rem;
    gap: 1rem;
}

.card-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border-radius: 25px;

    padding: 4rem 1.5rem;
}

.card-focus{
    border: 1px rgba(255, 255, 255, 0.5) solid;
}