.buttons{
    display: flex;
    gap: 2rem;
}

/* Buttons */
.main-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px; /* identical to box height */
    color: #151515;
    background-color: white;
    border-radius: 66px;
    /* border-radius: 0; */
    padding: 17px 37px;
    outline: none;
    border: none;
    transition: border-radius 0.3s ease-out; /* Smooth transition for background and text color */
}

.main-button:hover {
    /* border-radius: 30px; */
    border-end-end-radius: 0;
}

.secondary-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px; /* identical to box height */
    color: #FAFAFA;
    background-color: transparent;
    border: 1px #F4F4F4 solid;
    border-radius: 66px;
    /* border-radius: 0; */
    padding: 15px 35px;
    transition: border-radius 0.3s ease-out; /* Smooth transition for background and text color */
}
.lineup-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px; /* identical to box height */
    color: #FAFAFA;
    background-color: transparent;
    border: 1px #F4F4F4 solid;
    border-radius: 66px;
    padding: 15px 23px;
    transition: background-color 0.3s ease-out, color 0.3s ease-out; /* Smooth transition for background and text color */
}
.lineup-buttons{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1.5rem;

    width: 100%;

    flex-wrap: wrap;
}

.secondary-button:hover {
    /* border-radius: 30px; */
    border-top-right-radius: 0;
}

.link-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px; /* identical to box height */
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.015em;
    text-decoration-line: underline;
    color: #FFFFFF;
    background-color: transparent;
}

.button-link-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-left: 1rem;
    transition: 0.3s gap ease-out;
    cursor: pointer;
}
.button-link-container:hover{
    gap: 1.3rem;
}

.icons{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .icon-container{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
  }
@media (max-width: 800px) {
    .lineup-buttons{
        flex-direction: column;
        align-items: start;
        width: 100%;
    }
    .lineup-button{
        min-width: 100%;
    }
    .lineup-buttonss{
        gap: 0.5rem;
        width: 100%;
    }
    .main-button, .secondary-button, .lineup-button{ 
        padding: 15px 20px;
        width: 50%;
    }
    .buttons{
        width: 100%;
        justify-content: space-between;
        gap: 1rem;
    }
  }
@media (max-width: 430px) {
    /* .buttons{
        flex-direction: column;
        align-items: start;
    } */
     .whatsapp{
        display: block;
        width: 100%;
     }
    .main-button, .secondary-button, .lineup-button{ 
        padding: 10px 20px;
    }

    .button-link-container{
        gap: 0.5rem;
        margin-left: 0;
    }
  }


  
