.about-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    width: 100%;
}

.about-page h2{
    margin-bottom: 6rem;
}

.experience-section{
    width: 100%;
}
.about-section{
    width: 100%;
    
}

.about-section h2, .experience-section h2 {
    min-width: 100%;
}

@media (max-width: 850px) {
    .about-page h2{
        text-align: start;
        margin-bottom: 0rem;
    }
}
/* @media (max-width: 460px) {
    .about-page h2{
        margin-bottom: 0%;
    }
    .about-page{
        margin: 0;
        padding: 0;
    }
} */