.footer{
    height: 40vh;
    background-color: #101010;
    /* background-image: url("../../public/footer.jpg"); */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 9rem;
    overflow: hidden;
    position: relative;
    align-self: flex-end;
    justify-self: flex-end;

    padding: 2rem;
    text-align: center;
}