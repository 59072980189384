.social-proof {
    width: 100%;
}

.social-proof-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    overflow: hidden; /* Prevent overflow */
}

.move-inside {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    background-color: rgba(0, 0, 0, 0.363);
    padding: 1rem 3rem;
    margin-top: 1rem;
    position: relative;
    border-radius: 160px;
    overflow: hidden; /* Ensure content does not overflow */
}

.company {
    max-height: 35px;
    max-width: 100%;
    height: auto;
    width: auto;
    flex-shrink: 1;
    
    overflow: hidden;
}


.nav-img{
    cursor: pointer;
}

@media (max-width: 850px) {
    .social-proof-container {
        gap: 1rem !important;
    }
    
.move-inside {
    padding: 1rem 1rem;
}
}


