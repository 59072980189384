.navbar{
    width: 100%;
    min-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: fixed;
    z-index: 100;
    top: 0;
    padding: 0 13%;

    transition: background-color 0.3s ease-out;
    /* background-color: #101010ea !important; */
    }
    @media (max-width: 1600px) {
        .navbar {
          padding: 0 5%;
          min-height: 10vh;
        }
      }
      @media (max-width: 950px) {
        .navbar {
            padding: 0 3%;
            min-height: 10vh;
            /* padding: 0 1.5rem; */
        }
      }

    .desktop-links{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        height: 100%;
        flex: 3;
    }
    .logo{
        max-width: 128px;
        cursor: pointer;
    }
    .logo-container{
        flex: 1;
        z-index: 20000;
    }
    .mobile-links{
        display: none;
    }
    nav ul{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    gap: 1.5rem;
    z-index: 1;
    }
    nav ul li{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    text-decoration: none;
    }
    nav ul li:hover{
    text-decoration: underline;
    }
    
    .social-links{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 2.5rem;
    /* width: 15%; */
    z-index: 1;
    }
    
    .social-link{
    height: 25px;
    cursor: pointer;
    }

    /* Hamburger Styles */
.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 20000;
}

.line {
    width: 30px;
    height: 2.5px;
    background-color: #d8cfcf; /* Change color as needed */
    margin: 0.25rem 0;
    transition: 0.3s; /* Smooth transition */
}

@media (max-width: 768px) {
    .navbar{
        min-height: 10vh ;
    }
    .hamburger {
        display: flex;
    }
    .desktop-links{
        display: none;
    }
    .mobile-links{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0%;
        left: 0;
        height: 100%;
        z-index: 2000;
        width: 100%;
        gap: 5rem;

        background-color: #000000;
    }
    .mobile-links ul{
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 25%;
    }
}
