/* Global Styles */

* {
  box-sizing: border-box !important; /* Ensures padding and borders do not affect element width/height */
  margin: 0;              /* Resets default margin */
  padding: 0;          /* Resets default padding */
}

body {
  font-family: 'Poppins', sans-serif; /* Set a default font for the body */
  line-height: 1.6;                   /* Improves readability */
  color: white;                        /* Default text color */
  background-color: #101010;          /* Light background color */
  min-height: 100vh;
  overflow-x: hidden;
  /* width: 100% !important; */
}
/* #root{

  display: flex;
  flex-direction: column;
  align-items: center;
} */

/* Optional: Add styles for links */
a {
  text-decoration: none;               /* Removes underlines from links */
  color: inherit;                      /* Inherit color from parent */
  font-style: none;
}

button {
  font-family: inherit;                /* Ensures buttons use the same font */
  cursor: pointer;                     /* Changes cursor to pointer on hover */
}

/* Additional base styles can be added as needed */

.background-image{
  position: absolute;
  height: 100%;
  width: 100%;
}

.op{
  opacity: 10%;
}

.main-container {
  /* min-height: 100% !important; */
  /* width: 74%; */
  padding: 0 13%;
  width: 100%;
  position: relative;
  padding-top: 20vh;
  max-width: 100%;
  overflow: hidden;
}

@media (max-width: 1600px) {
  .main-container {
    padding: 0 5%;
    padding-top: 20vh;
  }
}
@media (max-width: 950px) {
  .main-container {
      padding: 0 3%;
      padding-top: 20vh;
      /* padding: 0 1.5rem; */
  }
}

img{
  display: block;
  max-width: 100%;
}