.flex-container{
    display: flex;
    margin-top: 5rem;
    min-width: 100%;
    gap: 3rem;
}
.flex-image{
    height: 450px;
    /* width: 480px; */
    border-radius: 20px;
    /* min-width: 90%; */

    object-fit: cover;
}

.flex-info-chunk{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 25px;
}

.flex-image-chunk{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: end;
}

.bullet-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
}

.bullet{
    text-decoration: none;
    font-style: none;
    list-style: none;
}

.circle{
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #659973;
}

.bullet-point{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 17px;
}

.extra-height{
    width: 90%;
    height: 800px;
}

@media (max-width: 850px) {
    .flex-container{
        margin-top: 4rem;
        flex-direction: column !important;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .flex-info-chunk{
        width: 100%;
        align-items: start;
        justify-content: center;
        gap: 1.5rem;
        text-align: start;
    }
    .flex-image-chunk{
        width: 100%;
    }
    .flex-image{
        width: 100%;
        height: 300px;
    }
    .extra-height{
        height: auto;
    }
  }

