.projects-page{
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}

.project-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    width: 100%;
}

.project-section-images{
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    /* gap: 3rem; */
    /* flex-wrap: wrap; */

    
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)) !important;

    grid-auto-flow: dense;

    gap: 2rem; /* Adjust the gap between images */
    width: 100%;
    box-sizing: border-box; /* Include padding in the width */
}

.project-section-image {
    width: 100%; /* Make images responsive */
    height: 480px; /* Maintain aspect ratio */
    display: block; /* Remove bottom space in images */
    object-fit: cover;

    
    border-radius: 20px;
    border-end-end-radius: 0;


    /* max-width: 22%;
    min-width: 10%; */
}
.project-section-image-container{
    overflow: hidden;
    border-radius: 20px;
    border-end-end-radius: 0;
    cursor: pointer;
}

.overlay-text{
    position: absolute;
    top: 46%;
    left: 29%;
    text-align: center;
}

.project-page-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}


@media (max-width: 850px) {
    .project-section-image{
        height: 250px;
    }
}