@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Main Headings */
.main-heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;  /* Default for larger screens */
    line-height: 142%; /* or 68px */
    color: #FFFFFF;
}

.secondary-heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 44.8px;
    line-height: 55px;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
}

.small-heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 35.2px;
    line-height: 43px; /* identical to box height */
    color: rgba(255, 255, 255, 0.95);
    line-height: 150%;
}

/* Text Headings */
.text-heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px; /* identical to box height */
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.85);
}

/* General Text */
.big-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
}

.text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.9);

    line-height: 170%;
}

/* Links */
.link {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px; /* identical to box height */
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: rgba(255, 255, 255, 0.75);
}

/* Media Queries */
@media (max-width: 950px) {
    .main-heading {
        font-size: 36px;  /* Adjusted for medium screens */
    }
    
    .secondary-heading {
        font-size: 32px;  /* Adjusted */
    }

    .small-heading {
        font-size: 28px;  /* Adjusted */
    }

    .text-heading {
        font-size: 16px;  /* Adjusted */
    }

    .big-text {
        font-size: 20px;  /* Adjusted */
    }

    .text {
        font-size: 14px;  /* Adjusted */
    }

    .link {
        font-size: 16px;  /* Adjusted */
    }
}

@media (max-width: 600px) {
    .main-heading {
        font-size: 28px;  /* Further adjustment for small screens */
    }
    
    .secondary-heading {
        font-size: 24px;  /* Further adjustment */
    }

    .small-heading {
        font-size: 20px;  /* Further adjustment */
    }

    .text-heading {
        font-size: 14px;  /* Further adjustment */
    }

    .big-text {
        font-size: 18px;  /* Further adjustment */
    }

    .text {
        font-size: 12px;  /* Further adjustment */
    }

    .link {
        font-size: 14px;  /* Further adjustment */
    }
}