.home-page{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7rem;
}

@media (max-width: 430px) {
    .home-page{
        gap: 3rem;
    }
  }
.hero-section{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 60vh;

    /* margin-bottom: 5rem; */
}

/* .main-image {
    height: 550px;
    width: 410px;
    object-fit: cover;
    mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
    -webkit-mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
} */

.fore{
    position: absolute;
    /* scale: 1.1; */
}

.info-chunk{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 26px;

    position: relative;

}

.backpop{
    position: absolute;
    
    background-color: rgba(0, 0, 0, 0.281);
    border-radius: 10%;
    filter: blur(50px);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

/* .image-chunk{
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: end;
} */
/* 
@media (max-width: 1110px) {
    .image-chunk {
        flex: 2;
    }
    .main-image {
        height: 450px;
        width: 350px;
    }
  } */

/* @media (max-width: 875px) {
    .hero-section{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }   
    .info-chunk{
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;
    } 
    .image-chunk{
        overflow: hidden;
    }
    .main-image{
        height: 300px;
        width: 500px;
        
        object-position: 0% 30%;
    }
} */


.social-proof{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}



/* EXPLAIN SECTION */
.explain-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.explain-section p {
    text-align: center;
}

/* NEW TEST */
.hide{
    display: none;
}
.info-chunk{
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1;
    /* height: 40vh; */
    /* margin-top: 5vh; */
    text-align: center;
    
}

p, h1, h2, h3{
    
    max-width: 950px;
}

.main-video-container{
    position: absolute !important;
    top: 0;
    left: 0%;
    z-index: 1;
    max-width: 100%;
    /* min-height: 90vh !important; */
    max-height: 90% !important;

    overflow: hidden;
    }

.main-video{
    width: 100%;
    filter: brightness(0.5);
}


@media (max-width: 1600px) {
    .hero-section{
        min-height: 50vh;
    }
}
@media (max-width: 1500px) {
    .main-video-container{
        max-width: unset;
        /* min-height: 90vh !important; */
        max-height: 100% !important;
        height: 90%;
    }
    .main-video{
        height: 100%;
        object-fit: cover;
    }    
    .hero-section{
        margin-bottom: 9rem;
    }
}

@media (max-width: 1190px) {
    .info-chunk{
        margin-top: -5vh;
    }
    .main-video-container{
        max-width: unset;
        /* min-height: 90vh !important; */
        max-height: 100% !important;
        height: 90%;
    }
    .main-video{
        height: 100%;
        object-fit: cover;
    }
}


@media (max-width: 480px) {
    .hero-section{
        margin-bottom: 6rem;
    }
}