/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

.modal-content {
    position: relative;
    border-radius: 20px;
    /* width: 90%; */
    height: 90%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.close-button {
    position: absolute;
    top: 10px;
    right: -0px;
    font-size: 35px;
    cursor: pointer;
}

.modal-image {
    /* max-width: 100%; */
    max-height: 100% !important;
    
    object-fit: cover;
}