.cool-projects-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4.5rem;

    width: 100%;
}

.project-showcase-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
}

.project-showcase{
    flex: 1;

    position: relative;

    overflow: hidden;
    
    height: 450px;

}
.project-showcase-image{
    width: 270px;
    height: 450px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 15px;

}

.project-showcase-info{
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 270px;
    padding: 0 1rem;
    
    bottom: 1.5rem;

    z-index: 10;
    position: absolute;
    cursor: pointer;

    pointer-events: none;

    transition: 0.35s opacity ease-out;
}


/* .right-arrow{
    width: 31px;
    height: 31px;
} */


@media (max-width: 850px) {
    .cool-projects-section{
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .project-showcase-container{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        width: 100%;
    }
    
    .project-showcase{
        height: 130px;
        border-radius: 15px;
        margin-top: 1rem;
    }
    .project-showcase-image{
        width: 100%;
        /* height: 25%; */
    }

    
    .project-showcase-info{
        width: 100%;
        top: 50%;
        bottom: 50%;
    }
    
  }
